<template>
  <div class="container">
    <el-dialog :title="dialogTitle+'设置' " width='700px' v-model="dialogVisible">
        <div class="wrap">
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="TaskName" label="入群欢迎语任务名称" v-if="type==0"></el-table-column>
                <el-table-column prop="" label="规则名称" v-if="type==1"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template #default="scope">
                        <el-button type="primary" @click="selectAction(scope.$index)" v-if="!scope.row.isSelect">选择</el-button>
                        <el-button type="primary" @click="delAction(scope.row,scope.$index)" v-if="scope.row.isSelect">取消选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAction">确 定</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {GroupBatchSetWelcomeMsg,GroupSetWelcomeMsg,WelcomeMsgSimpleList} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';

export default {
  name: "GroupSettingDialog",
  setup(props, context) {
    const dialogVisible = ref(false);
    const type=ref(0)
    const tableData=ref([])
    const groupObj=ref([])
    const selectList = ref([]);
    const sid=ref("")
    const dialogTitle=ref("入群欢迎语")
    const canEdit = ref(false);
    onMounted(()=>{})
    
    //pageType 0:入群欢迎语 1.防骚扰规则
    function initCop(pageType = 0, groupData,edit,arr=[],SID) {
      dialogVisible.value = true;
      type.value = pageType;    
      canEdit.value=edit || false
      groupObj.value=groupData
      sid.value=SID
      if(pageType==1){
        dialogTitle.value='防骚扰规则'
      }
      selectList.value=arr? JSON.parse(JSON.stringify(arr)): [];
      resetData();
      searchAction()
    }

    function resetData(){
        tableData.value=[]
    }
    
    function searchAction() {
        if(type.value==0){
            let arr=[]
            if(canEdit.value){
                arr=groupObj.value.map(item=>item.WelcomeMsg.WelcomeMsgID)
            }
            WelcomeMsgSimpleList({IsEnable:1}).then(res=>{
                tableData.value=res.List
                tableData.value.forEach(item=>{
                    if(canEdit.value&&arr.indexOf(item.ID) > -1){
                        item.isSelect = true;
                    }
                })
               
            })
        }
        
    }
    /*****************点击事件*********************/
    //选择
    function selectAction(index) {
        let item=tableData.value[index]
        if(item.isSelect){
            let tag = -1;
            selectList.value.forEach((v, key) => {
                if (v.ID == item.ID) {
                    tag = key;
                    return false;
                }
            });
            if (tag > -1) {
                selectList.value.splice(tag, 1);
            }
            item.isSelect = false;
        }else {
            item.isSelect = true;
            if (type.value == 0 && selectList.value.length > 0) {
                tableData.value.forEach((item,index2)=>{
                    if(index2!=index){
                        item.isSelect=false
                    }
            })
                // selectList.value[0].isSelect = false;
                selectList.value = [item];
                return;
            }
            selectList.value.push(item);
      }
    }
    //取消选择
    function delAction(item,index) {
        tableData.value.forEach((ele) => {
            if (ele.ID == item.ID) {
                ele.isSelect = false;
                return false;
            }
        });
         selectList.value=[]
    }
    //确认
    function confirmAction() {
        if(type.value==0){
            if(!canEdit.value){
                let param={
                    GroupIDs:groupObj.value.map(item=>item.GroupID),
                    WelcomeMsgID:selectList.value.length>0? selectList.value[0].ID : 0
                }
                GroupBatchSetWelcomeMsg(param).then(res=>{
                    ElMessage({
                        type: "success",
                        message: "提交成功",
                    });
                    dialogVisible.value = false;
                    context.emit("onConfirm");
                })
            }else{
                let param={
                    GroupID:sid.value,
                    WelcomeMsgID:selectList.value.length>0? (selectList.value[0].ID||selectList.value[0]) : 0
                }
                GroupSetWelcomeMsg(param).then(res=>{
                    ElMessage({
                        type: "success",
                        message: "提交成功",
                    });
                    dialogVisible.value = false;
                    context.emit("onConfirm");
                })
            }
        }else{
            ElMessageBox.confirm(`确定后修改现有的群聊邀请设置，您确定这样做吗？`, '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                ElMessage({
                    type: 'success',
                    message: '操作成功!',
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
            })
        }
        
    }
    return {
      initCop,
      dialogVisible,
      dialogTitle,
      type,
      confirmAction,
      resetData,
      tableData,
      searchAction,
      selectAction,
      delAction,
      groupObj,
      canEdit,
      sid
    };
  }
};
</script>
<style lang='scss' scoped>
.wrap{
    margin: 30px 20px;
    display: flex;
    align-items: center;
}
:deep().el-table--border::after, .el-table--group::after{
    width: 0;
}
</style>