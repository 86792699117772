import $axios from '../assets/js/request.js'
let baseURL = process.env.VUE_APP_URL

//获得企业成员列表
export function CompanyContactList(data) {
    return $axios.post(`${baseURL}/CompanyContact/List`,data);
}

//设置RPA助手
export function CompanyContactSetRPA(data) {
    return $axios.post(`${baseURL}/CompanyContact/SetRPA`,data);
}
//获得RPA助手列表
export function CompanyContactRPAList(data) {
    return $axios.post(`${baseURL}/CompanyContact/RPAList`,data);
}

//设置多个群聊的RPA助手
export function GroupBatchSetRPA(data) {
    return $axios.post(`${baseURL}/Group/BatchSetRPA`,data);
}

//设置群聊的RPA助手
export function GroupSetRPA(data) {
    return $axios.post(`${baseURL}/Group/SetRPA`,data);
}

//导入助手名单
export function RobotBatchUpdate(data) {
    return $axios.post(`${baseURL}/Robot/BatchUpdate`,data);
}

