<template>
  <div>
    <div class="table-head">
      <div class="table-search">
          <div class="search-item">
            <span>群名称:</span>
            <el-input v-model="searchOption.key" placeholder="请输入群名称"></el-input>
          </div>
          <div class="tag-wrap search-item">
            <span>群标签:</span>
            <div class="tag-search" @click="tagSelectAction">
                <p>{{searchOption.groupTag==''?'全部':searchOption.groupTag}}</p>
                <div><i class="el-icon-arrow-down"></i></div>
            </div>
          </div>
          <div class="search-item">
            <span>助手:</span>
            <el-select v-model="searchOption.robot" value-key="RobotID" placeholder="全部" filterable>
              <el-option v-for="item in filterData.info.robots" :key="item.RobotID" :label="item.NickNames" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>RPA助手:</span>
            <el-select v-model="searchOption.RpaSerialNo" placeholder="全部" filterable>
              <el-option v-for="item in filterData.info.rpaList" :key="item.RpaSerialNo" :label="item.NickNames" :value="item.RpaSerialNo">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>群主:</span>
            <el-select v-model="searchOption.groupOwner" value-key="MemberSerialNo" placeholder="全部">
              <el-option v-for="item in filterData.info.groups" :key="item.MemberSerialNo" :label="item.MemberNickName" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>入群欢迎语:</span>
            <el-select v-model="searchOption.welcomeMsg" value-key="ID" placeholder="全部">
              <el-option v-for="item in filterData.info.welcomes" :key="item.ID" :label="item.TaskName" :value="item">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="search-item">
            <span>防骚扰:</span>
            <el-select v-model="searchOption.harass">
              <el-option v-for="item in statusList" :key="item.MemberSerialNo" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div class="search-item">
            <span>禁止改群名:</span>
            <el-select v-model="searchOption.RestrictName">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <span>群聊邀请:</span>
            <el-select v-model="searchOption.AutoAuth">
              <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>

      </div>
      <div class="search-btn">
        <el-button type="primary" @click="searchAction">搜索</el-button>
        <el-button @click="resetAction">重置</el-button>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-head">
       <div class="table-btn">
          <el-button type="primary" @click="addSelectedTagAction">批量打标签</el-button>
          <el-button type="primary" @click="delSelectedTagAction">批量移除标签</el-button>
          <!-- <el-button type="primary" @click="addSelectedTagAction">群标签设置</el-button> -->
          <el-button type="primary" @click="welcomeAction">入群欢迎语设置</el-button>
          <el-button type="primary" @click="harassAction">防骚扰设置</el-button>
          <el-button type="primary" @click="stopNameAction">禁止改群名设置</el-button>
          <el-button type="primary" @click="groupActiveAction">群邀请设置</el-button>
          <el-button type="primary" @click="downloadeAction" v-loading.fullscreen.lock="fullscreenLoading">下载群码</el-button>
          <el-popover
            placement="top-start"
            :width="160"
            trigger="hover"
          >
            <template #reference>
              <el-button type="primary">批量修改群名</el-button>
            </template>
              <div class="pop-cell">
                <el-button plain @click="downNowGroupList">导出当前群列表</el-button>
                <el-button plain @click="uploadEditAction">导入修改的内容</el-button>
                <el-button plain @click="checkEditDetail">查看修改明细</el-button>
              </div>
          </el-popover>
          <el-button type="primary" @click="setRPAAction">设置RPA助手</el-button>
       </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
		<div class="list-title">
			<div>群列表(共<span>{{pageObj.TotalRow||0}}</span>个群)</div>
      <el-button type="primary" @click="exportAction">导出</el-button>
		</div>

      <el-table :data="tableData" style="width: 100%" border ref="tableRef" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" align="center" fixed></el-table-column>
        <el-table-column label="群名称" prop="GroupName" fixed></el-table-column>
        <el-table-column label="群标签" width="160">
            <template #default="scope">
                <div class="tag-wrap">
                    <div v-for="(item,index) in scope.row.GroupTagList" :key="index" class="tag-cell">
                        <!-- <p>{{item.title}}</p> -->
                        <el-tag type="success">{{item.TagName}}</el-tag>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="群主" prop="AdminNickName">
          <template #default="scope">
            <div class="tag-wrap">
              <div>{{scope.row.AdminNickName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="管理员">
            <template #default="scope">
                <div class="tag-wrap">
                    <div v-for="(item,index) in scope.row.ManagerList" :key="index" class="tag-cell">
                        <p>{{item.NickName}}</p>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="管理助手" prop="MonitorNickName">
            <template #default="scope">
            <div class="tag-wrap">
              <div>{{scope.row.MonitorNickName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="RpaNickName" label="RPA助手"></el-table-column>
        <el-table-column label="群人数" width="100" prop="UserCount">
            <template #default="scope">
                <div class="action-wrap">
                    <router-link :to="{name:'MemberList',query:{SID:scope.row.GroupID,SerialNo:scope.row.MonitorSerialNo}}">{{scope.row.UserCount}}</router-link>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="WelcomeMsg" label="入群欢迎语" ></el-table-column>
        <el-table-column prop="AntiHarassmentRuleCount" label="防骚扰规则">
          <template #header>
              <div class="cell-header">
                  <span>防骚扰规则</span>
                  <el-popover placement="top-start" :width="300" trigger="hover"
                      content="群内客户发送的消息内容命中规则时，该客户将会被提出群聊或者发送警告消息（规则明细请在企微配置，私域管家助手负责引用）">
                      <template #reference>
                          <el-icon :size="15" class="theme-color"><InfoFilled /></el-icon>
                      </template>
                  </el-popover>    
              </div>
          </template>
          <template #default="scope">
              <div>{{scope.row.AntiHarassmentRuleCount}}</div>
          </template>
        </el-table-column>
        <el-table-column label="禁止修改群名" width="120">
          <template #header>
              <div class="cell-header">
                  <span>禁止修改群名</span>
                  <el-popover placement="top-start" :width="300" trigger="hover"
                      content="启用后，群成员将不能修改群名，群主或群管理员可以修改。">
                      <template #reference>
                          <el-icon :size="15" class="theme-color"><InfoFilled /></el-icon>
                      </template>
                  </el-popover>    
              </div>
          </template>
            <template #default="scope">
                <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.RestrictName" active-color="#13ce66" @change="changeRestrictName(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="群聊邀请">
          <template #header>
              <div class="cell-header">
                  <span>群聊邀请</span>
                  <el-popover placement="top-start" :width="300" trigger="hover"
                      content="启用后，群成员需要群主或群管理员确认才能邀请成员进群。扫描二维码进群将同时停用。">
                      <template #reference>
                          <el-icon :size="15" class="theme-color"><InfoFilled /></el-icon>
                      </template>
                  </el-popover>    
              </div>
          </template>
            <template #default="scope">
                <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.AutoAuth" active-color="#13ce66" @change="changeAutoAuth(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="群码">
            <template #default="scope">
                <div v-if="scope.row.isRefresh" class="refresh-text" @click='refreshGroupCode(scope.row)'>刷新群码</div>
                <el-image v-else class="groupImg" :src="scope.row.GroupQrCode.QrCodeUrl"  @click="imageClick(scope.row)"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="160">
          <template #default="scope">
              <div class="action-wrap">
                  <el-popover
                    placement="top-start"
                    popper-class=""
                    :width="160"
                    trigger="hover"
                  >
                    <template #reference>
                      <el-button type="primary">操作</el-button>
                    </template>
                    <div class="pop-cell">
                      <el-button plain @click="editGroupName(scope.row)">编辑群名称</el-button>
                      <el-button plain @click="editGroupManager(scope.row,scope.$index)">编辑群管理员</el-button>
                      <el-button plain @click="pullAction(scope.row,scope.$index)">拉人</el-button>
                    </div>
                  </el-popover>
                  <el-button type="primary" @click="detailAction(scope.row)">查看</el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="foot-wrap">
          <div>
              <el-checkbox v-model="selectAll" class="selectAll" :checked="selectAll" @change="changeSelectAll()">全选</el-checkbox>
          </div>
          <TableFoot :pagination="pageObj"  @changePage="changePage" @changeSize="changeSize" />
      </div>
      
    </div>
    <GroupFieldCop ref="groupField2Ref" @onConfirm="sureSelectAction"/>
    <GroupFieldCop ref="groupField3Ref" @onConfirm="sureMulSelectAction"/>
    <SwitchDialogCop ref="switchDialogRef" @onConfirm="searchAction"/>
    <GroupSettingDialog ref="groupSettingRef" @onConfirm="searchAction"/>
    <EditGroupNameCop ref="editNameRef" @onConfirm="sureGroupNameAction"/>
    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
    <GroupSelectCop ref="groupSelect2Dialog" @onConfirm="chooseGroup2Action" />
    <GroupHarassCop ref="groupHarassRef" @onConfirm="searchAction" />
    <QrcodeShowCop ref="qrcodeShowRef" />
    <UploadGroupInfo ref="uploadInfoRef" @updateName="searchAction"/>
    <SetRPACop ref="setRpaRef" @onConfirm="searchAction"/>
  </div>
</template>
<script>
import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import GroupFieldCop from '@/components/GroupFieldCop'
import SwitchDialogCop from '@/components/GroupManager/SwitchDialog'
import GroupSettingDialog from '@/components/GroupManager/GroupSettingDialog'
import GroupHarassCop from '@/components/GroupManager/GroupHarassCop'
import QrcodeShowCop from '@/components/GroupManager/QrcodeShowCop'
import EditGroupNameCop from '@/components/GroupManager/EditGroupName'
import UploadGroupInfo from "@/components/GroupManager/UploadGroupInfo"
import SetRPACop from "@/components/GroupManager/SetRPACop"
import { ElMessageBox,ElMessage } from 'element-plus';
import {GetGroupList,GetGroupOwnerName,WelcomeMsgSimpleList,GroupSetGroupAdmin,GroupInviteCompanyContact2Group,SetAutoAuth,SetRestrictName,GetGroupQRcode,BatchDownloadGroupQRcode,GroupModifyExportGroupList} from '@/helper/group'
import {GetRobotSimpleList} from '@/helper/robot'
import {CompanyContactRPAList} from '@/helper/company'
import GroupSelectCop from "@/components/GroupSelectCop";
import { InfoFilled } from "@element-plus/icons";
import Tool from '@/assets/js/tools'
import { useStore } from "vuex";

export default {
  name: "GroupDetail",
  components: {
      TableFoot,GroupFieldCop,SwitchDialogCop,GroupSettingDialog,QrcodeShowCop,EditGroupNameCop,GroupSelectCop,GroupHarassCop,UploadGroupInfo,SetRPACop,InfoFilled
  },
  beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from.path == '/groupinfo'||from.path =="/memberlist"){
                to.meta.getStore = true
            }
        })
    },
  beforeRouteLeave(to, from, next){
        this.$store.commit("GroupDetail/setData",{
            pageObj:this.pageObj,
            searchOption:this.searchOption,
        })
        next()
  },
  setup() {
    const router = useRouter();
    const tagID =  useRoute().query.TagID;
    const tagName =  useRoute().query.TagName;
    const SID =  useRoute().params.SID;
    const groupField2Ref = ref(null)
    const groupField3Ref = ref(null)
    const switchDialogRef=ref(null)
    const groupSettingRef=ref(null)
    const groupSelectDialog=ref(null)
    const groupSelect2Dialog=ref(null)
    const uploadInfoRef=ref(null)
    const editNameRef=ref(null)
    const selectAll = ref(false)
    const tableRef= ref(null)
    const groupHarassRef = ref(null)
    const fullscreenLoading = ref(false)
    const qrcodeShowRef = ref(null)
    const setRpaRef =ref(null)
    
    const filterData=reactive({
        info:{groups:[],robots:[],welcomes:[],rpaList:[]}
    })
    const tableData = ref([]);
    const store = useStore()
    const route = useRoute()

    onMounted(()=>{
      resetStore()
      if(tagID&&tagID.length>0){
        searchOption.groupTag=tagName
        searchOption.groupTags=[{ID:tagID,TagName:tagName}]
        searchOption.groupTagIDs=[tagID]
      }
      GetRobotSimpleList({Name:""}).then(res=>{
        filterData.info.robots=[{SerialNo:'',NickName:'全部',WxAlias:""}].concat(res.List||[])
        for(let item of filterData.info.robots){
          item.NickNames=item.NickName+'('+item.WxAlias+')'
          let str='全部()'
          if(str.indexOf(item.NickNames)>-1){
            item.NickNames=item.NickName
          }
           if(SID==item.SerialNo){
            searchOption.robot = item
          }
        }
        searchAction()
      })
      GetGroupOwnerName({AdminNickName:""}).then(res=>{
        filterData.info.groups =[{MemberSerialNo:'',MemberNickName:'全部'}].concat(res.List||[])
      })
      WelcomeMsgSimpleList({IsEnable:""}).then(res=>{
        filterData.info.welcomes=[{ID:-1,TaskName:'无欢迎语'}].concat(res.List||[])
      })
      CompanyContactRPAList({GroupID:""}).then(res=>{
        filterData.info.rpaList =[{RpaSerialNo:"",NickName:'全部',UserID:""}].concat(res.List || []) 
        for(let item of filterData.info.rpaList){
          item.NickNames=item.NickName+'('+item.UserID+')'
          let str='全部()'
          if(str.indexOf(item.NickNames)>-1){
            item.NickNames=item.NickName
          }
        }
      })
      // searchAction()
    })

    function resetStore(){
        if(route.meta.getStore&& store.state.GroupDetail.pageObj&&store.state.GroupDetail.searchOption){
            pageObj.TotalRow= store.state.GroupDetail.pageObj.TotalRow,
            pageObj.PageIndex= store.state.GroupDetail.pageObj.PageIndex,
            pageObj.PageSize=store.state.GroupDetail.pageObj.PageSize,
            searchOption.robot= store.state.GroupDetail.searchOption.robot,
            searchOption.status= store.state.GroupDetail.searchOption.status
            searchOption.groupTag= store.state.GroupDetail.searchOption.groupTag
            searchOption.groupTags= store.state.GroupDetail.searchOption.groupTags
            searchOption.groupTagIDs= store.state.GroupDetail.searchOption.groupTagIDs
            searchOption.groupOwner= store.state.GroupDetail.searchOption.groupOwner
            searchOption.key= store.state.GroupDetail.searchOption.key
            searchOption.welcomeMsg= store.state.GroupDetail.searchOption.welcomeMsg
            searchOption.AutoAuth= store.state.GroupDetail.searchOption.AutoAuth
            searchOption.RestrictName= store.state.GroupDetail.searchOption.RestrictName
            searchOption.harass= store.state.GroupDetail.searchOption.harass
            searchOption.orderBy= store.state.GroupDetail.searchOption.orderBy
        }
    }

    //处理群列表请求参数
    function dealwithRequestData(){
      return {
          PageNum:pageObj.PageIndex,
          PageSize:pageObj.PageSize,
          TagIDs:searchOption.groupTagIDs||[],
          XJoin:0,
          SerialNo:searchOption.robot?searchOption.robot.SerialNo:'',
          AdminSerialNo:searchOption.groupOwner.MemberSerialNo,
          Name:searchOption.key,
          OrderBy:searchOption.orderBy||0,
          WelcomeMsgID:searchOption.welcomeMsg.ID,
          RestrictName:searchOption.RestrictName,
          AutoAuth:searchOption.AutoAuth,
          RpaSerialNo:searchOption.RpaSerialNo
      }
    }

  /******************点击事件*********************/
  function editRPA(item){
    setRpaRef.value.initCop(1,[],item)
  }
  //设置rpa
  function setRPAAction(){
    if(selectData.value.length>0){
        setRpaRef.value.initCop(0,selectData.value)
      } else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }

  }

  //导出当前群列表
  function downNowGroupList(){
    let param={
          TagIDs:searchOption.groupTagIDs||[],
          XJoin:0,
          SerialNo:searchOption.robot?searchOption.robot.SerialNo:'',
          AdminSerialNo:searchOption.groupOwner.MemberSerialNo,
          Name:searchOption.key,
          OrderBy:searchOption.orderBy||0,
          WelcomeMsgID:searchOption.welcomeMsg.ID,
          RestrictName:searchOption.RestrictName,
          AutoAuth:searchOption.AutoAuth
    }
    GroupModifyExportGroupList(param).then(res=>{
        window.location.href = res.File;
          ElMessage({
            type: 'success',
            message: '导出成功!',
          });
    })    
  }

  //导入修改的内容
  function uploadEditAction(){
    uploadInfoRef.value.initCop()
  }

  //查看修改明细
  function checkEditDetail(){
    router.push({name:'GroupNameInfo'})
  }

  //导出
  function exportAction() {
      let param = dealwithRequestData()
      param.PageSize=999999;
      param.Output = 1;
      GetGroupList(param).then(res=>{
          window.location.href = res.File;
          ElMessage({
            type: 'success',
            message: '导出成功!',
          });
      })
  }

  //查看
  function detailAction(item) {
    router.push({name: "GroupInfo",query:{SID:item.GroupID}})
  }
  //欢迎语设置
  function welcomeAction() {
    if(selectData.value.length>0){
      groupSettingRef.value.initCop(0,selectData.value,false)
    }else{
       ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
    }
  }

  //防骚扰
  function harassAction() {
    if(selectData.value.length>0){
      groupHarassRef.value.initCop(selectData.value)
    }else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
     }
  }
  //禁止改群名
  function stopNameAction(){
    if(selectData.value.length>0){
      switchDialogRef.value.initCop(0,selectData.value)
    }else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }
  }

  //改变群聊
  function changeAutoAuth (item){
    SetAutoAuth({
        GroupID:item.GroupID,
        Status:item.AutoAuth
    }).then().catch(err=>{
      item.AutoAuth= !item.AutoAuth
    })
  }

  //禁止修改群名
  function changeRestrictName(item){
    SetRestrictName({
        GroupID:item.GroupID,
        Status:item.RestrictName
    }).then().catch(err=>{
      item.RestrictName= !item.RestrictName
    })
  }

  //群聊邀请
  function groupActiveAction() {
    if(selectData.value.length>0){
      switchDialogRef.value.initCop(1,selectData.value)
    }else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }
  }
  //下载群码
  function downloadeAction(){
    if(selectData.value.length>0){
      fullscreenLoading.value = true
      BatchDownloadGroupQRcode({GroupIDs:selectData.value.map(item=>item.GroupID)}).then(res=>{
        ElMessage.success("下载成功")
        window.location.href= res.File
        fullscreenLoading.value = false
      }).catch(err=>{
        fullscreenLoading.value = false
      })
    }else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }
    
  }

  //刷新群码
  function refreshGroupCode(row){
    GetGroupQRcode({GroupID:row.GroupID,GroupSerialNo:row.GroupSerialNo}).then(res=>{
        ElMessage.success('刷新成功')
        searchAction()
    })
  }

  //图片点击
  function imageClick(item){
    qrcodeShowRef.value.initCop(item)
  }

    //全选
    function changeSelectAll(){
      tableRef.value.toggleAllSelection();
      if(selectData.value.length>=tableData.value.length){
        selectAll.value = false;
      } else {
        selectAll.value = true;
      }
        
    }

    //加所有选中
    function addSelectedTagAction(){
      if(selectData.value.length>0){
        let groupIDs = []
        let tagids = [];
        let tagArr = [];
        selectData.value.forEach(item=>{
          groupIDs.push(item.GroupID)
          for(let v of item.GroupTagList){
            if(tagids.indexOf(v.TagID)<0){
              tagids.push(v.TagID)
              v.ID = v.TagID;
              tagArr.push(v)
            }
          }
        })
        groupField3Ref.value.initCop(4,[],1,groupIDs)
        // groupField3Ref.value.initCop(1,tagArr,1,groupIDs)
      } else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }
        
    }

    //所有选中加标签
    function sureMulSelectAction(){
        tableRef.value.clearSelection();
        selectAll.value = false;
        searchAction()
    }

    //删除所有标签
    function delSelectedTagAction(){
       if(selectData.value.length>0){
        let groupIDs = []
        let tagids = [];
        // let tagArr = [];
        selectData.value.forEach(item=>{
          groupIDs.push(item.GroupID)
          for(let v of item.GroupTagList){
            if(tagids.indexOf(v.TagID)<0){
              tagids.push(v.TagID)
              v.ID = v.TagID;
              // tagArr.push(v)
            }
          }
        })
        groupField3Ref.value.initCop(4,[],1,groupIDs,true)
      } else {
        ElMessage({
            type: 'info',
            message: '请选择至少一个群',
        });
      }
    }

    //table 选择变更
    const selectData = ref([])
    function handleSelectionChange(val) {
        selectData.value = val;
        if(selectData.value.length >= tableData.value.length){
          selectAll.value = true;
        } else {
          selectAll.value = false;
        }
    }

    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: -1 },{ label: "开启", value: 1 },
      { label: "关闭", value: 0 }]
    let searchOption = reactive({
        robot:{},
        status:'全部',
        groupTag:"",
        groupTags:[],
        groupTagIDs:[],
        groupOwner:{},
        key:'',
        welcomeMsg:{},
        AutoAuth:-1,
        RestrictName:-1,
        harass:0,
        orderBy:0,
        RpaSerialNo:""
    })

    //执行搜索
    function searchAction(){
        GetGroupList(dealwithRequestData()).then(res=>{
          pageObj.TotalRow = res.TotalCount;
          tableData.value = res.List || [];
          tableData.value.forEach(item=>{
            if(item.WelcomeMsg){
              item.WelcomeMsg=JSON.parse(JSON.stringify(item.WelcomeMsg.TaskName)) 
            }
            item.endTime = Tool.GMTToStr(item.GroupQrCode.QrCodeLastUpdateTime,2,7)
            item.refreshTime = Tool.GMTToStr(item.GroupQrCode.QrCodeLastUpdateTime,2,5)
            if(new Date().getTime()>new Date(item.refreshTime).getTime()){
              item.isRefresh= true
            }
          })
        })
    }

    //重置条件
    function resetAction(){
        searchOption.key = '';
        searchOption.status = '全部';
        searchOption.robot = {};
        searchOption.groupTag = '';
        searchOption.groupTags = [];
        searchOption.groupTagIDs = [];
        searchOption.groupOwner = {};
        searchOption.AutoAuth=-1
        searchOption.RestrictName=-1
        searchOption.orderBy = 0;
        searchOption.harass=0
        searchOption.welcomeMsg={}
        searchOption.RpaSerialNo = ""
        pageObj.PageIndex= 1
        searchAction();
    }

    //群标签筛选
    function tagSelectAction(){
        groupField2Ref.value.initCop(2,searchOption.groupTags)
    }

    //标签选择确认
    function sureSelectAction(radio,arr){
      let demoArr = []
      let demoIds = []
      arr.forEach(item=>{
        demoArr.push(item.TagName)
        demoIds.push(item.ID)
      })
      searchOption.groupTag= demoArr.join(",");
      searchOption.groupTags= arr;
      searchOption.groupTagIDs= demoIds;
    }

    //修改群名
    function editGroupName(item) {
      editNameRef.value.initCop(item)
    }

    //确认群名
    function sureGroupNameAction() {
      searchAction()
    }

    let holderIndex=0
    
    //编辑群管理
    function editGroupManager(item,index) {
      holderIndex=index
      groupSelectDialog.value.initCop(5,item.ManagerList,true,item,item.GroupID,item.MonitorSerialNo)
    }

    //确认群管理
    function chooseGroupAction(arr) {
      let item =tableData.value[holderIndex]
      console.log(item);
      let param={
        RobotSerialNo:item.MonitorSerialNo,
        GroupID:item.GroupID,
        MemberSerialNos:arr.map(item=>item.MemberSerialNo)
      }
      GroupSetGroupAdmin(param).then(res=>{
        searchAction()
      })
    }

      //拉人
    function pullAction(item,index){
      holderIndex=index
      groupSelect2Dialog.value.initCop(6,[],true,item,item.GroupID,item.MonitorSerialNo)
    }
    //确认拉人
    function chooseGroup2Action(arr){
      let item =tableData.value[holderIndex]
      let param={
        GroupID:item.GroupID,
        MemberSerialNos:arr.map(item=>item.MemberSerialNo),
      }
      GroupInviteCompanyContact2Group(param).then(res=>{
        searchAction()
        ElMessage({
            type: 'success',
            message: '操作成功!',
          });
      })
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
        TotalRow: 0,
        PageIndex: 1,
        PageSize: 10,
    })
    //页码改变
    function changePage(index) {
        pageObj.PageIndex = index;
        searchAction();
    };
    function changeSize(size) {
        pageObj.PageSize = size;
        pageObj.PageIndex = 1;
        searchAction();
    }

    return {
      tableData,
      searchOption,
      statusList,
      editNameRef,
      editGroupName,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      filterData,
      tagSelectAction,
      groupField2Ref,
      sureSelectAction,
      addSelectedTagAction,
      delSelectedTagAction,
      selectAll,
      changeSelectAll,
      tableRef,
      groupField3Ref,
      sureMulSelectAction,
      handleSelectionChange,
      selectData,
      dealwithRequestData,
      detailAction,
      welcomeAction,
      harassAction,
      stopNameAction,
      groupActiveAction,
      switchDialogRef,
      groupSettingRef,
      exportAction,
      sureGroupNameAction,
      editGroupManager,
      chooseGroupAction,
      groupSelectDialog,
      pullAction,
      chooseGroup2Action,
      groupSelect2Dialog,
      groupHarassRef,
      changeAutoAuth,
      changeRestrictName,
      downloadeAction,
      refreshGroupCode,
      fullscreenLoading,
      imageClick,
      qrcodeShowRef,
      downNowGroupList,
      uploadEditAction,
      checkEditDetail,
      uploadInfoRef,
      setRPAAction,
      setRpaRef,
      editRPA
    };
  },
};
</script>
<style lang="scss" scoped>
.action-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    img{
      width: 15px;
      height: 13px;
      margin-left: 2px;
    }

}

.groupImg{
  width:40px;
  height:40px;
  cursor:pointer;
}

.tag-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span{
      margin-right:5px;
    }

    .tag-cell{
        padding: 5px;
        margin:2px 0;
        // height: 20px;
        font-size: 12px;
        // background-color: #97D1F4;
        // color: #787C7E;
        // border: 1px solid #ccc;
        // border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.refresh-text{
  color:$color-common;
  font-size:12px;
  cursor: pointer;
}

.tag-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    .tag-search{
        width: 200px;
        height: 26px;

        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 12px;
        border: 1px solid #dddddd;
        border-radius: 5px;

        p{
            width: 180px;
            height: 100%;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            padding: 0 5px;
            box-sizing: border-box;
            
        }
        div{
            width: 20px;
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
    
}

.foot-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-left: 3px;

        .selectAll{
            margin: 0 10px;
        }
    }
}
.list-title  div span{
  color:$color-common;
}
.table-search{
    justify-content: space-between;
    padding: 15px 5px;
    box-sizing: border-box;
    margin: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 290px);
    grid-gap: 20px;
    .search-item{
      display: flex;
      align-items: center;
      font-size: 14px;
      text-align: left;
    }
}
.search-btn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.table-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 56px;
    padding: 5px 10px;
    flex-wrap: wrap;
}
:deep().el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: $color-common ;
  border-color: $color-common ;
}
.pop-cell{
  display: flex;
  flex-direction: column;
  align-items: center;
  :deep().el-button{
    width: 100%;
    margin: 5px 0;
  }
}

.cell-header {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}
// .el-button--default:focus, .el-button--default:hover{
//   background: #ffffff !important;
//   border-color: $color-common !important;
//   color: $color-common !important;
// }
</style>