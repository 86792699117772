<template>
  <div class="container">
    <el-dialog title="设置RPA助手" width='650px' v-model="dialogVisible">

        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" class="ruleForm">
            <el-form-item label="RPA助手：" prop="RpaSerialNo">
                <el-select v-model="ruleForm.RpaSerialNo">
                    <el-option
                        v-for="item in RPAList"
                        :key="item.RpaSerialNo" :label="item.NickName+'('+item.UserID+')'" :value="item.RpaSerialNo"
                    />
                </el-select>
                <div>
                    <p>一个群只能绑定一个RPA助手</p>
                    <p>若此处设置RPA助手若不在群内，将设置不成功</p>
                </div>
            </el-form-item>
        </el-form>

        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, unref } from "vue";
import { ElMessageBox,ElMessage } from 'element-plus';
import {CompanyContactRPAList,GroupBatchSetRPA,GroupSetRPA} from '@/helper/company'
import _ from "lodash";
export default {
  name: "SetRPACop",
  setup(props, context) {
    const dialogVisible = ref(false);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      RpaSerialNo: ""
    });
    const rules = reactive({
      RpaSerialNo: [
        { required: true, message: "请选择", trigger: "blur" },
      ],
    });

    const RPAList = ref([])

    onMounted(()=>{
        CompanyContactRPAList({GroupID:""}).then(res=>{
            RPAList.value = res.List || []
        })
    })

    const selectList = ref([])
    const pageType = ref(0)
      const groupId= ref("")
    function initCop(type,arr,item,SID) {
        console.log(SID);
        dialogVisible.value = true;
        selectList.value = arr || []
        pageType.value = type
        if(pageType.value==1){
            groupId.value = SID
        }
        resetData()
        loadData(item)
    }

    function resetData(){
        ruleForm.RpaSerialNo = ""
    }
    
  
    function loadData(item) {
        if(item){
            ruleForm.RpaSerialNo = item.RpaSerialNo
        }
    }
    /*****************点击事件*********************/
     //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
            let param={
                RpaSerialNo:ruleForm.RpaSerialNo,
            }
            if(pageType.value==0){
                param.GroupIDs=selectList.value.map(item=>item.GroupID)
                 GroupBatchSetRPA(param).then(res=>{
                    ElMessage({
                        type: "success",
                        message: "提交成功",
                    });
                    dialogVisible.value = false;
                    context.emit("onConfirm");
                })
            }else{
                param.GroupID = groupId.value
                 GroupSetRPA(param).then(res=>{
                    ElMessage({
                        type: "success",
                        message: "提交成功",
                    });
                    dialogVisible.value = false;
                    context.emit("onConfirm");
                })
            }
           

        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      RPAList,
      selectList,
      pageType,
      groupId
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>