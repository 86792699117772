<template>
  <div class="qr-container">
    <el-dialog title="群二维码"  width="400px" v-model="dialogVisible" center>
      <div class="card">
        <h3>{{info.data.GroupName}}</h3>
        <img class="image" :src="info.data.GroupQrCode.QrCodeUrl" />
        <p>该二维码{{info.data.endTime}}前有效，重新进入将更新</p>

        <div class="bottom-wrap">
          <el-button type="primary" @click="downloadAction">下载二维码</el-button>
          
          <el-button class="copy-btn" ref='copy' type="primary" :data-clipboard-text="info.data.GroupQrCode.QrCodeUrl" @click='copyAction(info.data)'>复制地址</el-button>
        </div>
    </div>
    </el-dialog>
    
      
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import Tool from "@/assets/js/tools.js";
import ClipboardJS from 'clipboard'

export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const copy=ref(null)
    const info = reactive({
        data:{}
    })
    function initCop(item) {
      dialogVisible.value = true;
      info.data = item||{}
      
    }
    /***************点击事件******************/

    //下载
    function downloadAction(){
      Tool.exportImage(info.data.GroupQrCode.QrCodeUrl,info.data.GroupQrCode.QrCodeName)
    }

    //复制
    function copyAction(){
      var clipboard = new ClipboardJS(copy.value.$el);
        clipboard.on("success", e => {
            ElMessage({
                type: 'success',
                message: '复制成功!',
            });
            clipboard.destroy();
        });
        clipboard.on("error", e => {
            Message({
                message: "该浏览器不支持自动复制",
                type: "warning"
            });
            clipboard.destroy();
        });
    }

    return {
      initCop,
      dialogVisible,
      downloadAction,
      copyAction,
      info,
      copy

    };
  },
};
</script>
<style lang='scss' scoped>
.card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  p{
    font-size: 12px;
    color:$color-text;
  }
}

.image{
  width: 200px;
  height: 200px;
  margin: 30px 0px 10px 0px;
}

.bottom-wrap{
  margin: 20px 0px;
}
</style>