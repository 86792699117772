<template>
  <div class="container">
    <el-dialog title="导入修改的内容" width='600px' v-model="dialogVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef"  class="ruleForm">
                <el-form-item label="任务名称：" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入任务名称" :maxlength="20" show-word-limit style="width:320px"/>
                </el-form-item>
                <el-form-item label="文件：" prop="folder" :label-width="90">
                    <p v-if="ruleForm.folder&&ruleForm.folder.length>0">{{ruleForm.folder}}</p>
                   <el-upload
                        class="avatar-uploader"
                        accept=".xlsx"
                        action="#"
                        :http-request="changeFolder"
                        :on-success="sumbitUpload"
                        ref="upload"
                        :show-file-list="false"
                        :limit="1"
                    >
                        <el-button type="primary" v-if="ruleForm.folder.length<=0">上传</el-button>
                        <el-button type="primary" v-else @click="sumbitUpload">重新上传</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
        <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref,nextTick } from "vue";
import { ElMessageBox,ElMessage } from 'element-plus';
import { GroupModifyCreate } from "@/helper/group";
import _ from "lodash"

export default {
  name: "UploadGroupInfo",
  setup(props, context) {
    const dialogVisible = ref(false);
    const upload=ref(null)
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      name: "",
      folder:[]
    });
    const rules = reactive({
      name: [
        { required: true, message: "请输入任务名称", trigger: "blur" },
      ],
      folder:[
        { required: true,message: "请选择文件"},
      ],
    });

    function validatorFile(rule, value, callback){
      if(ruleForm.folder.length<=0){
        callback(new Error("请选择文件"));
      }else{
        callback()
      }
    }

    let foldObj=reactive({})

    onMounted(()=>{})
    
   
    function initCop() {
      dialogVisible.value = true;
      resetData();
      nextTick(()=>{
        ruleFormRef.value.clearValidate('folder');
        ruleFormRef.value.clearValidate('name');
        upload.value.clearFiles();
      })
    }

    function resetData(){
       ruleForm.name=''
       ruleForm.folder=[]
    }
   
    /*****************点击事件*********************/
    //上传
    function changeFolder(event){
      foldObj.value=event.file
      ruleForm.folder=event.file.name
      ruleFormRef.value.clearValidate('folder');
    }

    //上传成功
    function sumbitUpload(){
        upload.value.clearFiles(); //上传成功之后清除历史记录
    }
    

    //确认
    const submitForm =_.debounce(async ()=> {
      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
          let param={
            Type:300002,
            Name:ruleForm.name
          }
          const formData = new FormData();
          formData.append("file", foldObj.value);
          param.formData = formData
          GroupModifyCreate(param).then(res=>{
            ElMessage.success({
              message: "上传成功",
              type: "success",
            });
            dialogVisible.value = false;
            context.emit("updateName");
          })
      } catch (error) {
        console.log(error);
      }
    }, 1000, {
        'leading': true, //在延迟开始前立即调用事件
        'trailing': false, //在延时结束后不调用,保证事件只被触发一次
    })
    return {
      initCop,
      upload,
      dialogVisible,
      submitForm,
      resetData,
      ruleForm,
      ruleFormRef,
      rules,
      changeFolder,
      sumbitUpload,
      foldObj,
      validatorFile
    };
  }
};
</script>
<style lang='scss' scoped>.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>