<template>
  <div class="container">
    <el-dialog title="修改群名" width='650px' v-model="dialogVisible">

        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" class="ruleForm">
            <el-form-item label="新的群名称：" prop="groupName">
                <el-input v-model="ruleForm.groupName" placeholder="请输入群名称" :maxlength="30" show-word-limit/>
            </el-form-item>
        </el-form>

        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {GroupModifyChatName} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';
import _ from "lodash";
export default {
  name: "AddDialogCop",
  setup(props, context) {
    const dialogVisible = ref(false);
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
      groupName: "",
    });
    const groupId=ref("")
    const rules = reactive({
      groupName: [
        { required: true, message: "请填写群名称", trigger: "blur" },
      ],
    });
    onMounted(()=>{})
    
    function initCop(item,SID) {
      dialogVisible.value = true;
      groupId.value=SID||item.GroupID
      ruleForm.groupName=item.GroupName
    }

   
    /*****************点击事件*********************/
     //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          let param={
           GroupID:groupId.value,
           Name:ruleForm.groupName
          }
          GroupModifyChatName(param).then(res=>{
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm");
          })
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      groupId
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>