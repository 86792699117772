<template>
  <div class="container">
    <el-dialog title="防骚扰规则设置" width='700px' v-model="dialogVisible">
        <div class="wrap">
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="RuleName" label="规则名称"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template #default="scope">
                        <el-button type="primary" @click="selectAction(scope.row)" >{{scope.row.isSelect?'取消选择':'选择'}}</el-button>
                       
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAction">确 定</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {GetAntiHarassmentRuleList,BatchSetAntiHarassmentRule} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';

export default {
  name: "GroupHarassCop",
  setup(props, context) {
    const dialogVisible = ref(false);
    const tableData=ref([])
    const groups = ref([])

    onMounted(()=>{})
    
    function initCop(arr) {
      dialogVisible.value = true;
      groups.value = arr
      
      resetData();
      searchAction()
    }

    function resetData(){
        tableData.value=[]
    }
    
    function searchAction() {
        GetAntiHarassmentRuleList({PageNum:1,PageSize:99999}).then(res=>{
            tableData.value=res.List
        })
        
    }
    /*****************点击事件*********************/
    //选择
    function selectAction(item) {
        item.isSelect=  !item.isSelect
    }
    //确认
    function confirmAction() {
        let arr =[]
        if(tableData.value.length>0){
          for(let item of tableData.value){
            if(item.isSelect){
              arr.push(item.RuleSerialNo)
            }
          }
        }
        
         ElMessageBox.confirm(`确定后将修改现有的防骚扰规则，您确定这样做吗？`, '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
              let param = {
                RuleSerialNos:arr,
                GroupIDs:groups.value.map(item=>item.GroupID)
              }
              BatchSetAntiHarassmentRule(param).then(res=>{
                ElMessage({
                    type: 'success',
                    message: '操作成功!',
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
              })
            })
        
    }
    return {
      initCop,
      dialogVisible,
      confirmAction,
      resetData,
      tableData,
      searchAction,
      selectAction,
      groups
    };
  }
};
</script>
<style lang='scss' scoped>
.wrap{
    margin: 30px 20px;
    display: flex;
    align-items: center;
}
:deep().el-table--border::after, .el-table--group::after{
    width: 0;
}
</style>