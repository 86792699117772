<template>
  <div class="container">
    <el-dialog :title="dialogTitle+'设置' " width='500px' v-model="dialogVisible">
        <div class="wrap">
            <div>{{dialogTitle}}设置:</div>
             <el-switch style="margin-left:10px" v-model="enable" active-color="#13ce66"/>
        </div>
        <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAction">确 定</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {BatchSetAutoAuth,BatchSetRestrictName} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';

export default {
  name: "SwitchDialog",
  setup(props, context) {
    const dialogVisible = ref(false);
    const type=ref(0)
    const dialogTitle=ref("禁止改群名")
    const enable=ref(true)
    const selectArr = ref([])
    onMounted(()=>{})
    
    //pageType 0:禁止改群名 1.群聊邀请
    function initCop(pageType = 0,arr) {
      dialogVisible.value = true;
      resetData();
      type.value = pageType;
      selectArr.value = arr;
      if(pageType==1){
        dialogTitle.value='群聊邀请'
      }
     
    }

    function resetData(){
        enable.value=true
    }
   
    /*****************点击事件*********************/

    //确认
    function confirmAction() {
   
        if(type.value==0){
            ElMessageBox.confirm(`确定后修改现有的禁止改群名设置，您确定这样做吗？`, '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
              BatchSetRestrictName({
                GroupIDs:selectArr.value.map(item=>item.GroupID),
                Status:enable.value?1:0
              }).then(res=>{
                ElMessage({
                    type: 'success',
                    message: '操作成功!',
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
              })
                
            })
        }else{
            ElMessageBox.confirm(`确定后修改现有的群聊邀请设置，您确定这样做吗？`, '确认提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
              BatchSetAutoAuth({
                GroupIDs:selectArr.value.map(item=>item.GroupID),
                Status:enable.value?1:0
              }).then(res=>{
                ElMessage({
                    type: 'success',
                    message: '操作成功!',
                });
                dialogVisible.value = false;
                context.emit("onConfirm");
              })  
            })
        }
        
    }
    return {
      initCop,
      dialogVisible,
      dialogTitle,
      type,
      confirmAction,
      enable,
      resetData,
      selectArr,
    };
  }
};
</script>
<style lang='scss' scoped>
.wrap{
    margin: 30px 20px;
    display: flex;
    align-items: center;
}
</style>